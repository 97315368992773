body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "EB Garamond", serif;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  letter-spacing: 0.01em;
  background-color: #000;
}

.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}
.logo {
  font-family: "EB Garamond", serif;
  position: fixed;
  font-size: 28px;
  left: 0;
  top: 0;
  padding: 0.45em 1.5em;
  margin: 0;
  font-weight: 500;
  z-index: 1000000;

  cursor: pointer;

  opacity: 1;
  transition: all 0.5s;

  color: rgb(255 255 255);
}

.logo:hover {
  opacity: 0.5;
}

.mail {
  position: absolute;
  right: 25%;
  top: 0.45em;
  font-size: 28px;
}

.mail a {
  text-decoration: none;
  color: black;
  opacity: 0.3;
  transition: all 0.5s;
  letter-spacing: 0;
  font-weight: 300;
}

.mail a:hover {
  opacity: 1;
}

.show {
  visibility: visible !important;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
.hide {
  visibility: none !important;
  animation: fadeout 0.8s;
  -moz-animation: fadeout 0.8s; /* Firefox */
  -webkit-animation: fadeout 0.8s; /* Safari and Chrome */
  -o-animation: fadeout 0.8s; /* Opera */
  transition: 0.8s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 414px) {
  .mail {
    padding-top: 1.95em !important;
    font-size: 21px;
    right: 20% !important;
  }
  .dAHnJY,
  .knnrxv {
    visibility: hidden;
  }
}

@media screen and (max-width: 1240px) {
  .mail {
    right: 10%;
  }
  .dAHnJY,
  .knnrxv {
    visibility: hidden;
  }
}

@media screen and (max-width: 823px) and (max-height: 480px) {
  .dAHnJY,
  .knnrxv {
    visibility: hidden;
  }

  .mail {
    padding-top: 2em !important;
    font-size: 21px;
    right: 13% !important;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loading_line_wrapper {
  width: 100%;
  height: 2px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
}

.loading_line {
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  transform-origin: 100% 0%;
  animation: kf_loading_line 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  background: #000;
  transform-origin: 0% 0%;
}

.loading_line_inner--1 {
  opacity: 0;
  animation: kf_loading_line_inner--1 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s
    infinite;
}

.loading_line_inner--2 {
  opacity: 1;
  animation: kf_loading_line_inner--2 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s
    infinite;
}

.loading_line_inner {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100%;
  height: 100%;
  background-color: rgb(234 234 234);
  transform-origin: 0% 0%;
}

@keyframes kf_loading_line {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes kf_loading_line_inner--1 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes kf_loading_line_inner--2 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
